import "@fortawesome/fontawesome-free/css/all.css"

import { graphql } from "gatsby"
import React from "react"
import debug from "debug"

import PageTemplate from "../components/page-template.component"
/* eslint-disable camelcase */
const IndexPage = props => {
  const pageData = props.preview ? props.preview.pageByd.revisions.nodes[0] : props.data.page
  debug(`IndexPage`)(pageData)
  const { title } = pageData
  return <PageTemplate title={title} {...props} />
}

export default IndexPage

export const queryHome = graphql`
  query homeQuery {
    page: wordpressPage(wordpress_id: { eq: 4 }) {
      ...WPPage
    }

    site: site {
      siteMetadata {
        title
      }
    }

    models: allWordpressWpModel {
      edges {
        node {
          ...ModelProfileFields
        }
      }
    }

    posts: allWordpressPost {
      edges {
        node {
          ...WPPostFields
        }
      }
    }
  }
`
